import {defaultNavigationConfig} from "./omnia/components/layouts/dashboard/config";
import LeonardoPreprocessorSettings from "./components/bh-backend/leonardo-preprocessors";

export const APP_SETTING = {

    /**
     * Domain Setup
     *
     * Specify the core domain and protocol of the application
     */
    domain: "metk.groon.io",
    protocol: "https",
    socket_protocol: 'wss',
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',

    launchpad: [
        {
            label: 'Create Text',
            description: 'Assistenten und individuelle KI Modelle, die Dich unterstützen.',
            value: '/on/beyond-hub/create-text',
            disabled: false,
            permissions: [],
            icon: 'File02',
        },
        {
            label: 'Create Images',
            description: 'Deine Bildsammlung und Verlauf an generierten Bildern und Visuals.',
            value: '/on/beyond-hub/create-images',
            disabled: false,
            permissions: [],
            icon: 'Image03',
        },
        {
            label: 'Projekte',
            description: 'Create Content, erledige Aufgaben, kommentiere und diskutiere.',
            value: '/on/beyond-hub/projects',
            disabled: false,
            permissions: [],
            icon: 'Headphones02',
        },
        {
            label: 'Stundenerfassung',
            description: 'Trage deine TROI Studen unkompliziert ein.',
            value: '/on/beyondh-hub/demo',
            disabled: true,
            permissions: [],
            icon: 'AlarmClock',
        },
        {
            label: 'Messenger',
            description: 'Schreibe mit deinen Kollegen und Teammitgliedern.',
            value: '/groon/chat',
            disabled: false,
            permissions: [],
            icon: 'MessageChatCircle',
        },
        {
            label: 'be.yond Hub',
            description: 'Nutze und erkunde die be.yond Methoden.',
            value: '/on/beyondh-hub/demo',
            disabled: true,
            permissions: [],
            icon: 'Rocket02',
        },
    ],

    /**
     * Sidebar
     *
     * Specify the sidebar items that shall be in the menu of this instance
     * Example:
     *      {
     *          title: 'Some Tool',
     *          path: '/custom-module/some-tool',
     *          icon: <FittingUntitledIcon />,
     *          items: [ ...children of the same type],
     *      },
     */
    navigation: [
        {
            items: [
                {
                    title: 'Homebase',
                    path: '/groon/launchpad',
                    icon: 'Rocket02',
                },
                {
                    title: "layout.assistant",
                    path: '/groon/assistant',
                    icon: 'MessageCheckCircle',
                },
                {
                    title: "layout.my_files",
                    path: '/groon/files',
                    permissions: ['my_files'],
                    icon: 'File02',
                },
                {
                    title: "layout.my_chats",
                    path: '/groon/chat',
                    icon: 'MessageChatCircle',
                    permissions: ['groon_chats'],
                    chip: 'unread',
                    module: 'intranet',
                },
                {
                    title: "layout.tickets",
                    path: '/groon/requests',
                    icon: 'Bookmark',
                    showIf: 'has_tickets',
                    chip: 'tickets',
                },
                {
                    title: "layout.data_analytics.title",
                    path: '/groon/analytics',
                    permissions: ['dashboards'],
                    icon: 'Data',
                    items: [
                        {
                            title: 'layout.data_analytics.dashboards',
                            path: '/groon/analytics/dashboards'
                        },
                        {
                            title: 'layout.data_analytics.data_hub',
                            permissions: ['omnia_data_overview'],
                            path: '/groon/analytics/datahub'
                        },
                        {
                            title: "layout.my_links",
                            path: '/groon/short-links',
                            permissions: ['my_short_links'],
                        },
                    ]
                },
            ]
        },
        {
            subheader: 'Verwaltung',
            items: [
                defaultNavigationConfig.ai,
                defaultNavigationConfig.erp,
                defaultNavigationConfig.crm,
                defaultNavigationConfig.cms,
                defaultNavigationConfig.pm,
            ]
        },
        {
            subheader: 'Produktivität',
            permissions: [],
            items: [
                {
                    title: 'Projekte',
                    path: '/on/beyond-hub/projects',
                    icon: 'Activity',
                    permissions: ['metk_projects'],
                },
                {
                    title: 'Assistenten',
                    path: '/on/beyond-hub/assistants',
                    icon: 'MessageSmileSquare',
                    permissions: ['beyond_hub', 'bh_customer_assistants']
                },
                {
                    title: 'Create Text',
                    path: '/on/beyond-hub/create-text',
                    icon: 'File02',
                    permissions: ['metk_chats', 'metk_ai']
                },
                {
                    title: 'Create Images',
                    path: '/on/beyond-hub/create-images',
                    icon: 'Image02',
                    permissions: ['metk_images', 'metk_ai']
                },
                {
                    title: 'be.yond Methodik',
                    path: '/on/beyond-hub/hub',
                    icon: 'PuzzlePiece01',
                    permissions: ['bh_certificates', 'bh_method'],
                    items: [
                        {
                            title: 'Analyse',
                            path: '/on/beyond-hub/hub/methods'
                        },
                        {
                            title: 'Visioneering',
                            path: '/on/beyond-hub/hub/methods'
                        },
                        {
                            title: 'Werte',
                            path: '/on/beyond-hub/hub/methods'
                        },
                        {
                            title: 'Vision/Mission',
                            path: '/on/beyond-hub/hub/methods'
                        },
                        {
                            title: 'Downloads',
                            path: '/on/beyond-hub/hub/methods'
                        },
                        // {
                        //     title: 'be.yond Zertifikat',
                        //     path: '/on/beyond-hub/hub/certs',
                        //     permissions: ['bh_certificates']
                        // },
                        // {
                        //     title: 'be.yond Methodik',
                        //     path: '/on/beyond-hub/hub/methods',
                        //     permissions: ['bh_method'],
                        //     items: [
                        //         {
                        //             title: 'Analyse',
                        //             path: '/on/beyond-hub/hub/methods'
                        //         },
                        //         {
                        //             title: 'Visioneering',
                        //             path: '/on/beyond-hub/hub/methods'
                        //         },
                        //         {
                        //             title: 'Werte',
                        //             path: '/on/beyond-hub/hub/methods'
                        //         },
                        //         {
                        //             title: 'Vision/Mission',
                        //             path: '/on/beyond-hub/hub/methods'
                        //         },
                        //         {
                        //             title: 'Downloads',
                        //             path: '/on/beyond-hub/hub/methods'
                        //         },
                        //     ]
                        // },
                    ]
                },
            ]
        }
    ],

    /**
     * Widgets
     *
     * Specify the widgets that shall be available in the dashboards of this instance
     * Example:
     *      {
     *          name: "Custom Widget",
     *          description: "This is a custom widget that does something",
     *          identifier: "UNIQUE-IDENTIFIER",
     *          module: "Custom Module",
     *          component: ReactComponentToUse
     *      },
     */
    widgets: [],

    /**
     * Services
     *
     * A list of all services (their identifiers!) that are available in this instance
     * Example:
     *     {
     *          name: "Service Name",
     *          identifier: "UNIQUE-IDENTIFIER"
     *     }
     */
    services: [
        {
            name: 'Beyond Hub | Light',
            identifier: 'beyond-hub-light',
            register_redirect: '/beyond-hub'
        },
        {
            name: 'Beyond Hub | Individual',
            identifier: 'beyond-hub-individual',
            register_redirect: '/beyond-hub'
        },
        {
            name: 'Beyond Hub | Occhio',
            identifier: 'beyond-hub-occhio',
            register_redirect: '/beyond-hub'
        },
    ],

    /**
     *
     */
    settings: [
        {
            label: 'Beyond Hub',
            value: 'beyond-hub',
            icon: 'Globe06',
            permissions: ['beyond_hub'],
            items: [
                {
                    name: 'beyond-hub.image-search-result-count',
                    label: 'Bilder-Suche Ergebnisse',
                    description: 'Die Anzahl an Ergebnissen, die bei der Bildersuche zurückgegeben wird.',
                    type: 'number'
                },
                {
                    name: 'beyond-hub.name-chat-delay',
                    label: 'Chat-Bennenung Verzögerung',
                    description: 'Die Anzahl an Nachrichten, nach denen der Chat automatisch benannt wird.',
                    type: 'number'
                },
                {
                    name: 'beyond-hub.generated-image-number',
                    label: 'Bilderzahl bei Generierung (Intern)',
                    description: 'Die Anzahl an Bildern, die von den Assistenten standardmäßig intern in GROON erzeugt wird',
                    type: 'number'
                },
                {
                    name: 'beyond-hub.generated-image-number-external',
                    label: 'Bilderzahl bei Generierung (Extern)',
                    description: 'Die Anzahl an Bildern, die von den Assistenten standardmäßig in be.yond Kontexten erzeugt wird',
                    type: 'number'
                },
            ]
        },
        {
            label: 'Leonardo Preprozessoren',
            value: 'beyond-hub-leonardo-preprocessors',
            icon: 'CpuChip01',
            permissions: ['beyond_hub'],
            component: <LeonardoPreprocessorSettings />
        },
    ],

    /**
     * The duration of how long things fade in
     */
    transitionDuration: 500

}
